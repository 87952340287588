import { Cookie } from "./cookie";

interface ITrackingCookie {
  accept: () => void;
  decline: () => void;
  clear: () => void;
}

export const trackingCookieName = "answer-analytics-active";

export const TrackingCookie: ITrackingCookie = {
  accept: () => Cookie.set(trackingCookieName, "true", { Secure: "true", days: 365 }),
  decline: () => Cookie.set(trackingCookieName, "declined", { Secure: "true", days: 365 }),
  clear: () => Cookie.delete(trackingCookieName),
};
