import { createGlobalStyle } from "styled-components";
import { setBreakPoints } from "css-in-js-media";
setBreakPoints({
  smallPhone: 320,
  phone: 375,
  tablet: 768,
  desktop: 1024,
  largeDesktop: 1440,
});
import media from "css-in-js-media";

export const colourYellow = "#FFDA00";
export const colourGrey1 = "#333f4c";
export const colourGrey2 = "#5a6675";
export const colourGrey3 = "#7c8795";
export const colourGrey4 = "#a2aab6";
export const colourGrey5 = "#d9e1e2";

export const GlobalStyle = createGlobalStyle`
  :root {
    // Colours
    // - Brand colours
    --colour-yellow: ${colourYellow};
    --colour-grey-1: ${colourGrey1};
    --colour-grey-2: ${colourGrey2};
    --colour-grey-3: ${colourGrey3};
    --colour-grey-4: ${colourGrey4};
    --colour-grey-5: ${colourGrey5};

    // - Other colours
    --colour-light-grey: #F3F6F6;
    --colour-dark-grey: #1D1D39;
    --colour-white: #FFFFFF;
    --colour-red: #FF0000;

    // - Colour usage
    --colour-primary: var(--colour-yellow);
    --colour-secondary: var(--colour-grey-1);
    --colour-text-dark: var(--colour-dark-grey);
    --colour-text-light: var(--colour-white);
    --colour-header-bg: var(--colour-grey-1);
    --colour-error: var(--colour-red);

    // - Gradients
    --colour-gradient-yellow-purple: linear-gradient(
            -40deg,
            #54018C 0%,
            #54018C 30%,
            #E0B600 92%,
            #E0B600 100%
    );
    --colour-gradient-yellow-blue: linear-gradient(
            -40deg,
            #003F7C 0%,
            #013F7B 30%,
            #E9BD00 92%,
            #E9BD00 100%
    );
    --colour-gradient-yellow-pink: linear-gradient(
            -40deg,
            #9D0172 0%,
            #9D0172 30%,
            #E4B900 92%,
            #E4B900 100%
    );
    --colour-gradient-yellow-brown: linear-gradient(
            -40deg,
            #6F0008 0%,
            #6F0008 30%,
            #E4B900 92%,
            #E4B900 100%
    );

    // - Hero Gradients
    --hero-colour-gradient-purple: linear-gradient(24deg, #C29D00 0%, #55018C 54%);
    --hero-colour-gradient-blue: linear-gradient(21deg, #C29D00 0%, #003F70 44%);
    --hero-colour-gradient-maroon: linear-gradient(22deg, #E4B900 0%, #6F0008 55%);
    --hero-colour-gradient-pink: linear-gradient(22deg, #E4B900 0%, #9D0172 65%);


    // Type
    --body-font-family: "Euclid Square", sans-serif;
    --body-font-size: 16px;
    --body-font-size-small: 12px;
    --body-font-weight: 400;
    --body-font-line-height: 1.444;
    --body-font-letter-spacing: 0;
    --body-font-colour: var(--colour-grey-1);

    ${media(">tablet")} {
        --body-font-size: 18px;
        --body-font-size-small: 14px;
    }

    --h1-regular-font-size: 48px;
    --h1-large-font-size: 110px;

    --h1-font-size: var(--h1-regular-font-size);
    --h1-font-weight: 500;
    --h1-font-line-height: 1.286;
    --h1-font-letter-spacing: 0;

    ${media(">tablet")} {
      --h1-font-size: var(--h1-large-font-size);
    }

    --h2-font-size: var(--h2-regular-font-size);
    --h2-regular-font-size: 36px;
    --h2-large-font-size: 36px;
    --h2-font-weight: 500;
    --h2-font-line-height: 1.286;
    --h2-font-letter-spacing: 0;

    ${media(">tablet")} {
      --h2-font-size: var(--h2-large-font-size);
      --h2-large-font-size: 50px;

    }

    --h3-font-size: var(--h3-regular-font-size);
    --h3-regular-font-size: 28px;
    --h3-large-font-size: 30px;

    --h3-font-weight: 500;
    --h3-font-line-height: 1.286;
    --h3-font-letter-spacing: 0px;

    ${media(">tablet")} {
      --h3-font-size: var(--h3-large-font-size);
    }

    --h4-font-size: var(--h4-regular-font-size);
    --h4-regular-font-size: 18px;
    --h4-large-font-size: 22px;

    --h4-font-weight: 500;
    --h4-font-line-height: 1.286;
    --h4-font-letter-spacing: 0px;

    ${media(">tablet")} {
      --h4-font-size: var(--h4-large-font-size);
    }

    //--h5-font-size: 110px;
    //--h5-font-weight: 500;
    //--h5-font-line-height: 1.286;
    //--h5-font-letter-spacing: 0.3px;


    // Layout
    --layout-max-content-width: calc(1200px + (var(--layout-grid-gap--desktop) * 2));
    --layout-grid--desktop: repeat(12, 1fr);
    --layout-grid-gap--desktop: 30px;
    --layout-block-space: calc(var(--layout-grid-gap--desktop) * 1.5);

    ${media(">tablet")} {
        --layout-block-space: calc(var(--layout-grid-gap--desktop) * 3);
    }

    // Can be overridden in child components
    --layout-container-grid: var(--layout-grid--desktop);
    --layout-container-grid-gap: var(--layout-grid-gap--desktop);

    // Animation
    --transition-fade-out: 0.5s;

    // Behaviour
    --behaviour-header-height: 100px;
    --behaviour-scroll-offset: var(--behaviour-header-height);
    --behaviour-header-bg-colour: transparent;
  }

  * {
    box-sizing: border-box;
  }

  html {
    font-family: var(--body-font-family);
    font-size: var(--body-font-size);
    line-height: var(--body-font-line-height);
    letter-spacing: var(--body-font-letter-spacing);
    color: var(--body-font-colour);
    scroll-padding-top: var(--behaviour-scroll-offset);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 500;
  }

  h1 {
    font-size: var(--h1-font-size);
    font-weight: var(--h1-font-weight);
    line-height: var(--h1-font-line-height);
    letter-spacing: var(--h1-font-letter-spacing);
  }

  h2 {
    font-size: var(--h2-font-size);
    font-weight: var(--h2-font-weight);
    line-height: var(--h2-font-line-height);
    letter-spacing: var(--h2-font-letter-spacing);
  }

  h3 {
    font-size: var(--h3-font-size);
    font-weight: var(--h3-font-weight);
    line-height: var(--h3-font-line-height);
    letter-spacing: var(--h3-font-letter-spacing);
  }

  h4 {
    font-size: var(--h4-font-size);
    font-weight: var(--h4-font-weight);
    line-height: var(--h4-font-line-height);
    letter-spacing: var(--h4-font-letter-spacing);
  }

  a {
    color: inherit;
    &:visited {
      color: inherit;
    }
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  address {
      font-style: normal;
  }

  table.data {
      border-collapse: collapse;

      th {
          background-color: var(--colour-yellow);
          border: 1px solid var(--colour-dark-grey);
      }

      td {
          border: 1px solid var(--colour-dark-grey);
          padding: 8px;
      }
  }

  ${media("<tablet")} {
      article figure {
          margin: 0;

          img {
              max-width: 100%;
          }
      }
  }
`;
