import * as React from "react";
import { BlockText } from "../styles/textStyles";
import styled from "styled-components";
import media from "css-in-js-media";
import { Link } from "./Link";

const HeaderNavStyled = styled.nav`
  display: none;
  margin: 0 20px;
  font-size: 14px;

  ${media(">tablet")} {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`;

const HeaderNavItems = styled.ul`
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  list-style: none;
  display: flex;
  justify-content: center;
`;

const HeaderNavItemStyled = styled.li`
  &:not(:last-child) {
    margin-right: 0.5em;
    ${media(">tablet")} {
      margin-right: 1.875em;
    }
  }
`;

const HeaderNavLink = styled(Link)`
  display: block;
  color: white;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  line-height: 21px;
  padding: 1em 0.25em;
  ${media(">phone")} {
    padding: 1em 0.5em;
  }
`;

const HeaderNavLinkText = styled(BlockText)`
  display: inline-block;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color var(--transition-fade-out);

  ${HeaderNavLink}.active &,
  ${HeaderNavLink}:hover &,
  ${HeaderNavLink}:focus & {
    border-bottom-color: var(--colour-primary);
  }
`;

interface HeaderNavItemProps {
  id: string;
  title: string;
  destination: string;
}

const HeaderNavItem = (link: HeaderNavItemProps): JSX.Element => {
  return (
    <HeaderNavItemStyled key={link.id}>
      <HeaderNavLink to={link.destination} activeClassName={`active`}>
        <HeaderNavLinkText>{link.title}</HeaderNavLinkText>
      </HeaderNavLink>
    </HeaderNavItemStyled>
  );
};
type HeaderNavProps = {
  links: HeaderNavItemProps[];
};

export const HeaderNav = ({ links }: HeaderNavProps) => {
  if (!links?.length) {
    return null;
  }
  return (
    <HeaderNavStyled>
      <HeaderNavItems>{links.map(HeaderNavItem)}</HeaderNavItems>
    </HeaderNavStyled>
  );
};
