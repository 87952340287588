import React, { useCallback } from 'react';
import useResizeObserver from "@react-hook/resize-observer";

export const useSize = (target: React.RefObject<HTMLElement>) => {
  const [height, setHeight] = React.useState(0);

  const updateHeight = useCallback(() => {
    if (target.current) {
      const newHeight = target.current.getBoundingClientRect().height;
      setHeight(newHeight);
    }
  }, [target]);


  React.useLayoutEffect(() => {
    updateHeight();
  }, [updateHeight, target]);

  // check window is available (i.e running on client-side)
  if (typeof window !== 'undefined') {
    useResizeObserver(target, updateHeight);
  }

  return height;
};