import { createGlobalStyle, css } from "styled-components";

import regularWoff from "../fonts/euclid-square/EuclidSquare-Regular-WebS.woff";
import regularWoff2 from "../fonts/euclid-square/EuclidSquare-Regular-WebS.woff2";

import mediumWoff from "../fonts/euclid-square/EuclidSquare-Medium-WebS.woff";
import mediumWoff2 from "../fonts/euclid-square/EuclidSquare-Medium-WebS.woff2";

import semiboldWoff from "../fonts/euclid-square/EuclidSquare-Semibold-WebS.woff";
import semiboldWoff2 from "../fonts/euclid-square/EuclidSquare-Semibold-WebS.woff2";

const fonts = [
  {
    font: [
      { file: regularWoff, format: "woff" },
      { file: regularWoff2, format: "woff2" },
    ],
    weight: 400,
  },
  {
    font: [
      { file: mediumWoff, format: "woff" },
      { file: mediumWoff2, format: "woff2" },
    ],
    weight: 500,
  },
  {
    font: [
      { file: semiboldWoff, format: "woff" },
      { file: semiboldWoff2, format: "woff2" },
    ],
    weight: 600,
  },
];

interface IFontFamily {
  font: { file: string; format: string }[];
  weight: number;
}

type IFontsFontFamilyTypes = IFontFamily[];

function createFonts(fonts: IFontsFontFamilyTypes) {
  let styles = "";

  fonts.forEach((weight) => {
    const fontFamilies = weight.font
      .map((font) => {
        return `url("${font.file}") format("${font.format}")`;
      })
      .join(",");
    styles += `
              @font-face {
                font-family: "Euclid Square";
                src: ${fontFamilies};
                font-display: swap;
                font-weight: ${weight.weight};
              }
            `;
  });

  return css`
    ${styles}
  `;
}

export const FontFamily = createGlobalStyle`
  ${createFonts(fonts)}
`;
