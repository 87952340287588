import * as React from "react";
import styled from "styled-components";

import { useLocation } from "@reach/router"; // this helps tracking the location
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";

import { Row } from "./Row";
import { Cookie } from "../utils/cookie";
import { PillButton } from "./buttons/PillButton";
import { Link } from "./Link";
import Close from "../images/icons/close.svg";
import { TrackingCookie } from "../utils/trackingCookie";

const DismissButton = styled.button`
  grid-column: 12;
  align-self: flex-start;
  appearance: none;
  border: 0;
  background: none;
  margin: 0;
  padding: 8px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color var(--transition-fade-out) ease-in-out,
    color var(--transition-fade-out) ease-in-out;
  svg {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }

  &:hover,
  &:focus {
    background-color: var(--colour-primary);
  }
`;

const CookieBannerStyled = styled(Row)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding-top: 30px;
  padding-bottom: 30px;
  z-index: 9;

  h2 {
    margin: 0;
  }

  .cookie-banner__title {
    font-size: var(--h3-font-size);
    line-height: 1;
  }

  .cookie-banner__accept,
  .cookie-banner__decline {
    font-size: var(--body-font-size-small);
    margin-right: 15px;
    width: 122px;
    line-height: 1;
  }
`;

const CookieBannerContent = styled.div`
  grid-column: 1 / 11;
`;

export function CookieBanner(): JSX.Element {
  const [trackingAccepted, setTrackingAccepted] = React.useState(false);
  const [trackingDeclined, setTrackingDeclined] = React.useState(false);
  const [trackingDismissed, setTrackingDismissed] = React.useState(false);
  const location = useLocation();

  const answerAnalyticsCookie = Cookie.get(`answer-analytics-active`);

  React.useEffect(() => {
    if (trackingAccepted) {
      TrackingCookie.accept();
      initializeAndTrack(location);
    }
  }, [trackingAccepted]);

  React.useEffect(() => {
    if (trackingDeclined) {
      TrackingCookie.decline();
    }
  }, [trackingDeclined]);

  if (
    typeof window === "undefined" ||
    trackingAccepted ||
    trackingDeclined ||
    trackingDismissed ||
    answerAnalyticsCookie === "true" ||
    answerAnalyticsCookie === "declined"
  ) {
    return <></>;
  }

  return (
    <CookieBannerStyled bg={`var(--colour-grey-5)`} className="cookie-banner">
      <CookieBannerContent className="cookie-banner__content">
        <p className="cookie-banner__copy">
          We are using cookies to give you the best experience on our website.
          You can <Link to={`/privacy-policy`}>find out more</Link> about which
          cookies we are using or switch them off in settings.
        </p>
        <div className="cookie-banner__controls">
          <PillButton
            tabindex="1"
            as="button"
            bg="var(--colour-grey-1)"
            color="var(--colour-white)"
            className="cookie-banner__accept"
            onClick={() => setTrackingAccepted(true)}
          >
            Accept
          </PillButton>
          <PillButton
            tabindex="2"
            as="button"
            bg="var(--colour-grey-1)"
            color="var(--colour-white)"
            className="cookie-banner__decline"
            onClick={() => setTrackingDeclined(true)}
          >
            Decline
          </PillButton>
        </div>
      </CookieBannerContent>
      <DismissButton
        aria-label="Dismiss"
        className="cookie-banner__dismiss"
        onClick={() => setTrackingDismissed(true)}
      >
        <Close />
      </DismissButton>
    </CookieBannerStyled>
  );
}
