import * as React from "react";
import styled from "styled-components";

export interface IPillButton {
  bg?: string;
  color?: string;
  className?: string;
  onClick?: () => void;
  children?: JSX.Element | JSX.Element[] | string | string[];
}

export const PillButton = styled.button<IPillButton>`
  padding: 0.7857em 1.357em;
  border: 0;
  border-radius: 1.5em;
  background: ${(props) => props.bg || `var(--colour-primary)`};
  color: ${(props) => props.color || `var(--body-font-colour)`};
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  &:hover,
  &:focus {
    text-decoration: underline;
  }
  &:focus {
    box-shadow: 0 0 0 3px inset currentColor;
  }
`;
