import React from "react";
import styled from "styled-components";
import media from "css-in-js-media";

interface MenuButtonProps {
  isMenuOpen: boolean;
  onTouch: () => void;
}

const MenuWrap = styled.button`
  --icon-colour: var(--colour-white);
  position: absolute;
  top: 10px;
  right: 30px;
  z-index: 99;
  width: 55px;
  height: 55px;
  margin-right: -15px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: 0;

  &.menuOpen {
    --icon-colour: var(--colour-grey-1);
    align-self: flex-end;

    .line {
      transform: rotate(135deg);
      /* Turns Lines Into X */
      &::before,
      &::after {
        top: 0;
        transform: rotate(90deg);
      }
    }

    .icon .line {
      background-color: var(--icon-colour);
    }
  }

  &:hover,
  &:focus-visible {
    --icon-colour: var(--colour-yellow);
  }

  &:focus-visible {
    border: 1px solid var(--colour-yellow);
  }

  ${media(">tablet")} {
    display: none;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 55px;
  height: 30px;
  padding: 15px;
  margin-bottom: 3px;
  background: transparent;

  .line {
    width: 100%;
    height: 2px;
    &::before,
    &::after {
      top: -8px;
      width: 100%;
      height: 2px;
    }
    &::after {
      top: 8px;
    }
  }
`;

const Line = styled.div`
  transition: all 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--icon-colour);

  /* Icon Lines - Top & Bottom */

  &::before,
  &::after {
    transition: all 0.4s ease;
    content: "";
    position: absolute;
    z-index: 1;
    background: inherit;
  }
`;

const MenuButton = ({ isMenuOpen, onTouch }: MenuButtonProps): React.ReactElement => {
  return (
    <MenuWrap className={isMenuOpen ? "menuOpen" : ""} onClick={onTouch} type={"button"} title="Menu" aria-expanded={isMenuOpen ? "true" : "false"}>
      <Icon className="icon">
        <Line className="line"/>
      </Icon>
    </MenuWrap>
  );
};

export default MenuButton;
