// [
//   {
//     id: "home",
//     title: "Home",
//     destination: "/",
//   },
//   {
//     id: "services",
//     title: "Services",
//     destination: "/services",
//   },
//   {
//     id: "careers",
//     title: "Careers",
//     destination: "/careers",
//   },
// ]

import { slugify } from "./slugify";

export interface IMenuItem {
  titleOverride: string;
  customLink: string;
  linkDestination: {
    id: string;
    title: string;
    slug: {
      current: string;
    };
  };
  _key: string;
}

export interface ILinkSet {
  id: string;
  title: string;
  destination: string;
}

export function transformMenuData(data: IMenuItem[]): ILinkSet[] {
  if (!data || data.length === 0) {
    return [];
  }

  return data
    .filter((item) => {
      if (!item.linkDestination && !item.customLink) {
        return false;
      }
      return true;
    })
    .map((item) => {
      const title = item.titleOverride
        ? item.titleOverride
        : item.linkDestination.title;
      const id = slugify(title);

      return {
        id,
        title,
        destination: item.customLink
          ? item.customLink
          : "/" + item.linkDestination.slug.current,
      };
    });
}
