import * as React from "react";
import "normalize.css";
import { GlobalStyle } from "../styles/global-styles";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { CookieBanner } from "./CookieBanner";
import { FontFamily } from "../styles/font-family";
import { graphql, useStaticQuery } from "gatsby";
import { transformMenuData } from "../utils/transformMenuData";

type LayoutProps = {
  transparentHeader: boolean;
  children?: React.ReactChildren;
};

export function Layout(props: LayoutProps): JSX.Element {
  const menuItems = useStaticQuery(graphql`
    fragment MenuItems on SanityMenuBuilderItem {
      titleOverride
      customLink
      linkDestination {
        id
        title
        slug {
          current
        }
      }
      _key
    }

    {
      sanitySiteSettings(_id: { eq: "siteSettings" }) {
        id
        mainMenuItems {
          ...MenuItems
        }
        footerMenu1Items {
          ...MenuItems
        }
      }
    }
  `);

  const headerLinkSet = transformMenuData(
    menuItems.sanitySiteSettings?.mainMenuItems
  );
  const footerLinkSet1 = transformMenuData(
    menuItems.sanitySiteSettings?.footerMenu1Items
  );

  const { children, transparentHeader } = props;
  return (
    <>
      <FontFamily />
      <GlobalStyle />
      <Header transparentHeader={transparentHeader} links={headerLinkSet} />
      {children}
      <Footer links={footerLinkSet1} />
      <CookieBanner />
    </>
  );
}
