import * as React from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";
import media from "css-in-js-media";
import useScrollPosition from "@react-hook/window-scroll";

import Logo from "../images/icons/logo.svg";
import { Link } from "./Link";
import { HeaderNav } from "./HeaderNav";
import { ILinkSet } from "../utils/transformMenuData";
import MenuButton from "./MenuButton";
import FullLogo from "../images/icons/logo--full--orange.svg";
import { useSize } from "../hooks/useSize";

interface IHeaderAnchor {
  transparentHeader: boolean;
}

const HeaderAnchor = styled.header<IHeaderAnchor>`
  ${(props) =>
    props.transparentHeader === false
      ? css`
          height: var(--behaviour-header-height);
        `
      : ``}
`;

const HeaderStyled = styled.div`
  width: 100%;
  top: 0;
  position: absolute;
  z-index: 5;
  background: var(--behaviour-header-bg-colour);

  ${media(">tablet")} {
    position: fixed;
    transition: background-color 0.3s;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  max-width: var(--layout-max-content-width);
  margin-left: 50%;
  padding: var(--behaviour-header-vertical-padding) var(--layout-grid-gap--desktop);
  transform: translateX(-50%);
  transition: padding var(--transition-fade-out) ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;

  ${media("<=tablet")} {
    justify-content: space-between;
  }
`;

const HeaderLogo = styled.h1`
  margin: 0;
  line-height: 1;
  font-size: 1rem;

  svg {
    transition: fill var(--transition-fade-out) ease-in-out;

    &:hover {
      fill: var(--colour-primary);
    }
  }
`;

const DropDownMenuWrapperWindow = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 10px;
  height: 100vh;
  pointer-events: none;
  overflow: hidden;

  ${media("<=tablet")} {
    display: block;
  }
`;

const DropDownMenuWrapper = styled.div`
  transition: transform 0.5s ease-in;
  position: relative;
  transform: translateX(-100%);
  z-index: 4;
  pointer-events: auto;
  height: 100%;
  background-color: #f3f6f6;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  will-change: transform;

  &.menuOpen {
    transition-timing-function: ease-out;
    transform: translateX(0);
  }
`;

const DropDownMenuContainer = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div`
  --link-spacing: 15px;
  a {
    display: block;
    padding-top: var(--link-spacing);
    padding-bottom: var(--link-spacing);
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    text-decoration: none;

    &:hover,
    &:focus-visible {
      text-decoration: underline;
    }
  }

  &:first-child {
    margin-top: -15px;
  }

  &:last-child {
    div {
      display: none;
    }
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #fecf04, #00c79e);
`;

const DropDownMenuLogo = styled(FullLogo)`
  margin-left: -15px;
  fill: var(--colour-grey-1);
`;

function setHeaderHeight(height: string | number) {
  document.documentElement.style.setProperty(`--behaviour-header-height`, `${height}px`);
}


type HeaderProps = {
  links: ILinkSet[];
  transparentHeader: boolean;
};

export const Header = ({ links, transparentHeader }: HeaderProps): JSX.Element => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const scrollY = useScrollPosition();
  const height = useSize(headerRef);

  React.useEffect(() => {
    setHeaderHeight(height);
  }, [height])

  const baseHeaderColour = transparentHeader ? `transparent` : `var(--colour-header-bg)`;

  React.useEffect(() => {
    if (scrollY > height) {
      document.documentElement.style.setProperty(
        `--behaviour-header-bg-colour`,
        `var(--colour-header-bg)`,
      );
      document.documentElement.style.setProperty(`--behaviour-header-vertical-padding`, `5px`);
    } else {
      document.documentElement.style.setProperty(`--behaviour-header-bg-colour`, baseHeaderColour);
      document.documentElement.style.setProperty(`--behaviour-header-vertical-padding`, `20px`);
    }
  }, [scrollY, baseHeaderColour]);

  return (
    <HeaderAnchor transparentHeader={transparentHeader}>
      {isMenuOpen && (
        <Helmet>
          <style>{`
            html {
              overflow: hidden;
            }
          `}</style>
        </Helmet>
      )}
      <HeaderStyled ref={headerRef}>
        <HeaderWrapper>
          <HeaderLogo>
            <Link to="/" aria-label="Answer">
              <Logo />
            </Link>
          </HeaderLogo>
          <HeaderNav links={links} />
          <MenuButton isMenuOpen={isMenuOpen} onTouch={() => setIsMenuOpen(!isMenuOpen)} />
          <DropDownMenuWrapperWindow>
            <DropDownMenuWrapper className={isMenuOpen ? "menuOpen" : ""}>
              <DropDownMenuContainer>
                {links.map((link, i) => (
                  <LinkWrapper key={i}>
                    <Link to={link.destination} onClick={() => setIsMenuOpen(false)}>
                      {link.title}
                    </Link>
                    <Line />
                  </LinkWrapper>
                ))}
              </DropDownMenuContainer>
              <DropDownMenuLogo />
            </DropDownMenuWrapper>
          </DropDownMenuWrapperWindow>
        </HeaderWrapper>
      </HeaderStyled>
    </HeaderAnchor>
  );
};
