interface ICookieOptions {
  [index: string]: string | number;
}

interface ICookie {
  get: (name: string) => string | undefined;
  set: (name: string, value: string, opts?: ICookieOptions) => void;
  delete: (name: string, opts?: ICookieOptions) => void;
}

export const Cookie: ICookie = {
  get: (name: string) => {
    if (typeof window === "undefined") {
      return;
    }
    const c = document.cookie.match(
      `(?:(?:^|.*; *)${name} *= *([^;]*).*$)|^.*$`
    )?.[1];
    if (c) return decodeURIComponent(c);
  },
  set: (name, value, opts = {}) => {
    if (typeof window === "undefined") {
      return;
    }

    /*If options contains days then we're configuring max-age*/
    if (opts.days && typeof opts.days === "number") {
      opts["max-age"] = opts.days * 60 * 60 * 24;

      /*Deleting days from options to pass remaining opts to cookie settings*/
      delete opts.days;
    }

    /*Configuring options to cookie standard by reducing each property*/
    const options = Object.entries(opts).reduce(
      (accumulatedStr, [k, v]) => `${accumulatedStr}; ${k}=${v}`,
      ""
    );

    /*Finally, creating the key*/
    document.cookie = name + "=" + encodeURIComponent(value) + options;
  },
  delete: (name, opts) => Cookie.set(name, "", { "max-age": -1, ...opts }),
  // path & domain must match cookie being deleted
};
