import * as React from 'react';

import { Layout } from './src/components/Layout';

/**
 * @param {JSX.Element} element
 * @param {any} props
 * @returns {JSX.Element}
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function wrapPageElement({ element, props }) {
  let transparentHeader = false;
  if (props.data?.sanityPage) {
    const firstElement = props.data.sanityPage?.content?.[0];
    transparentHeader =
      firstElement._type === "heroArea" && (firstElement.heroType === "image" || firstElement.heroType === "text" && firstElement.backgroundColourType === "gradient");
  } else if (props.path === "/insights/") {
    transparentHeader = true;
  }
  return (
    <Layout transparentHeader={transparentHeader} {...props}>
      {element}
    </Layout>
  );
}
