import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";

const maxWidth = 135

const IsoAccreditationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: calc(var(--layout-grid-gap--desktop) / 4);
  font-size: 12px;
  
  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: fit-content;
    max-width: ${maxWidth}px
  }
`;

export const IsoAccreditation = () => (
  <IsoAccreditationContainer>
    <StaticImage src="../images/icons/isoqar-logo.jpg" alt="ISOQAR registered badge" width={maxWidth} />
    <div>
      <div>Certificate Number 1122</div>
      <div>ISO 27001: 2013</div>
    </div>
  </IsoAccreditationContainer>
);
