import * as React from "react";
import { BlockText } from "../styles/textStyles";
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import styled from "styled-components";
import { PillButton } from "./buttons/PillButton";
import { linkDestination } from "../utils/getLinkDestination";

export type LinkProps = React.PropsWithChildren<GatsbyLinkProps<any>>;

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
export const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  ...other
}: LinkProps): JSX.Element => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

interface StyledLinkStylesProps extends GatsbyLinkProps<any> {
  bg?: string;
}

const StyledLinkStyles = styled(Link)<StyledLinkStylesProps>`
  text-decoration: none;
  font-size: var(--body-font-size-small);
  font-weight: 500;
  display: inline-block;
  position: relative;
  overflow: hidden;

  span {
    display: inline-block;
    position: relative;
    padding-bottom: 2px;
    border-bottom: 1px solid
      ${(props) =>
        props.bg && props.bg === "yellow"
          ? `var(--colour-white)`
          : `var(--colour-primary)`};
    z-index: 1;
  }

  &:before {
    content: "";
    display: block;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1);
    transform: translateY(100%);
    transition: transform 0.3s; // Exit speed
  }

  &:focus-visible:before,
  &:hover:before {
    transition: transform 0.1s; // Entry speed
    transform: translateY(0);
  }
`;

type StyledLinkProps = LinkProps & { bg?: string };

export const StyledLink = ({
  bg,
  children,
  ...other
}: StyledLinkProps): JSX.Element => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <StyledLinkStyles bg={bg} {...other}>
      <BlockText>{children}</BlockText>
    </StyledLinkStyles>
  );
};

const PillButtonLinkStyled = styled(PillButton)<any>`
  background: ${(props) =>
    props.background && props.background === "yellow"
      ? `var(--colour-white)`
      : `var(--colour-primary)`};
`;

export const ButtonLink = ({
  bg,
  children,
  ...other
}: StyledLinkProps): JSX.Element => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <PillButtonLinkStyled background={bg} as={Link} {...other}>
      <span>{children}</span>
    </PillButtonLinkStyled>
  );
};
