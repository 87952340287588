import * as React from "react";
import styled, { css } from "styled-components";
import media from "css-in-js-media";

interface RowProps {
  children: React.ReactNode;
  className?: string;
  bg?: string;
  sectionElement?: boolean;
  verticalSpacing?: string;
}

export const Container = styled.div`
  max-width: var(--layout-max-content-width);
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--layout-grid-gap--desktop);
  padding-right: var(--layout-grid-gap--desktop);

  ${({ verticalSpacing }: { verticalSpacing?: string }) =>
    verticalSpacing
      ? css`
          padding-top: ${verticalSpacing};
          padding-bottom: ${verticalSpacing};
        `
      : ""}
  ${media(">tablet")} {
    display: grid;
    grid-template-columns: var(--layout-container-grid);
    grid-gap: var(--layout-container-grid-gap);
  }
`;

const Wrapper = styled.div`
  background: ${(props: { bg?: string }) => props.bg || "transparent"};
`;

export function Row(props: RowProps): JSX.Element {
  const { bg, children, sectionElement, verticalSpacing, ...other } = props;
  const wrapperElement = sectionElement ? "section" : "div";
  if (!bg) {
    return (
      <Container
        as={wrapperElement}
        verticalSpacing={verticalSpacing}
        {...other}
      >
        {children}
      </Container>
    );
  }
  return (
    <Wrapper bg={bg} as={wrapperElement} {...other}>
      <Container verticalSpacing={verticalSpacing}>{children}</Container>
    </Wrapper>
  );
}
